import React, { useState } from 'react';
import './ExpiryFeedback.css';
import { useContent } from '../hooks/useContent';

const ExpiryFeedback = ({ userId }) => {
  const content = useContent('expiryFeedback');
  const [rating, setRating] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const location = process.env.REACT_APP_LOCATION;

  const handleRating = async (value) => {
    setRating(value);
    
    // Send rating feedback immediately
    const ratingData = {
      questions: [
        {
          question: content.questions.rating.text,
          answer: value,
          type: "rating"
        }
      ],
      metadata: {
        userId: userId,
        location: location,
        userLanguage: localStorage.getItem('userLanguage') || 'English',
        timestamp: new Date().toISOString()
      }
    };

    try {
      await fetch('https://i6569suty5.execute-api.eu-west-2.amazonaws.com/prototype/mt-feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(ratingData)
      });
    } catch (error) {
      console.error('Error submitting rating:', error);
    }
  };

  const handleSubmit = async () => {
    // Only send text feedback
    if (feedback.trim()) {
      const feedbackData = {
        questions: [
          {
            question: content.questions.feedback.text,
            answer: feedback,
            type: "text"
          }
        ],
        metadata: {
          userId: userId,
          location: location,
          userLanguage: localStorage.getItem('userLanguage') || 'English',
          timestamp: new Date().toISOString()
        }
      };

      setSubmitted(true);

      try {
        await fetch('https://i6569suty5.execute-api.eu-west-2.amazonaws.com/prototype/mt-feedback', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(feedbackData)
        });
      } catch (error) {
        console.error('Error submitting feedback:', error);
      }
    }
  };

  if (!content) return null;

  return (
    <div className="expiry-overlay">
      <div className="expiry-content">
        <h1 className="expiry-title">{content.title}</h1>
        
        {!submitted && (
          <p className="expiry-intro">{content.intro}</p>
        )}
        
        {!submitted ? (
          <>
            <div className="survey-question" style={{ textAlign: 'left' }}>
              <p style={{ fontWeight: 'bold' }}>{content.questions.rating.text}</p>
              <div className="rating-container">
                <div className="rating-labels">
                  <span className="rating-label">{content.questions.rating.labels.min}</span>
                  <span className="rating-label">{content.questions.rating.labels.max}</span>
                </div>
                <div className="rating-buttons">
                  {[...Array(11)].map((_, index) => (
                    <button
                      key={index}
                      className={`rating-button ${rating === index ? 'selected' : ''}`}
                      onClick={() => handleRating(index)}
                    >
                      {index}
                    </button>
                  ))}
                </div>
              </div>
            </div>

            <div className="survey-question" style={{ textAlign: 'left' }}>
              <p style={{ fontWeight: 'bold' }}>{content.questions.feedback.text}</p>
              <textarea
                className="feedback-textarea"
                placeholder={content.questions.feedback.placeholder}
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
              />
            </div>

            <button 
              className="popup-button primary submit-button" 
              onClick={handleSubmit}
              disabled={feedback.trim() === ''}
            >
              {content.buttons.submit}
            </button>
          </>
        ) : (
          <p style={{ textAlign: 'center' }} className="thank-you-message">
            {content.thankYou}
          </p>
        )}
      </div>
    </div>
  );
};

export default ExpiryFeedback;